@import "variables";

#role-management-mobile {
  color: $primary-text-color;
  .header-page {
    width: 100%;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: $primary-text-color;

    .header-title {
      font-size: 16px;
      font-weight: 700;
    }

    .header-right {
      display: flex;
      align-items: center;
      .btn-search,
      .btn-create {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn-create {
        border-radius: 4px;
        background-color: $primary-color;
        margin-left: 24px;

        .icon-create {
          color: #fff;
        }
      }
    }
  }

  .page-body {
    width: 100%;
    padding: 16px 8px;

    .account-item {
      width: 100%;
      padding: 16px;
      border-radius: 4px;
      background-color: #fff;

      .account-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .switch {
          background-image: none;
        }

        .account-head-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: calc(100% - 44px);

          .btn-detail {
            width: 32px;
            height: 32px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f5f6f7;
          }

          .btn-detail:last-child {
            margin-left: 8px;
          }
        }
      }

      .account-info-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .account-info-label {
          width: 100px;
          font-size: 14px;
          font-weight: 500;
        }

        .account-info-value {
          width: calc(100% - 100px);
          font-size: 14px;
          font-weight: 400;
        }
      }

      .account-info-row:not(:first-child) {
        margin-top: 16px;
      }
    }

    .account-item:not(:first-child) {
      margin-top: 8px;
    }
  }

  .overlay {
    filter: grayscale(100%) blur(3px);
    opacity: 1; /* Adjust the opacity as needed */
  }
}

.popup-search {
  left: 8px !important;
  color: $primary-text-color;

  .box-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
  }

  .input-search {
    width: 100%;
    height: 36px;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 4px;
  }

  .date-picker {
    input {
      width: 100%;
      height: 36px;
      border: 1px solid $border-color;
      border-radius: 4px;
      font-size: 13px;
      margin-bottom: 4px;
    }
  }

  .select-role {
    border-radius: 4px;
    border: 1px solid $border-color;
    
    button {
      border: none;
    }

    label {
      display: none;
    }
  }

  .btn-search {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #A6A1FD;
    color: $primary-color;
    margin-top: 12px;
    background-color: #fff;
  }
}
