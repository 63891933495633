@import "variables";

#edit-profile-mobile {
  width: 100%;
  color: $primary-text-color;

  .form-profile {
    width: 100%;
    padding-bottom: 100px;

    .form-info {
      width: calc(100% - 16px);
      margin: 16px 8px;
      background-color: #fff;
      border-radius: 4px;

      .form-header {
        width: 100%;
        height: 68px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        .header-label {
          font-size: 16px;
          font-weight: 600;
        }

        .btn-edit {
          border: 4px;
          border: 0.5px solid #d9e1e9;
          background-color: #fff;

          .icon-edit {
            color: $primary-color;
          }
        }
      }

      .form-body {
        width: 100%;
        padding: 24px 16px;

        .avatar-group {
          margin-bottom: 16px;
          width: 88px;
          height: 88px;
          border-radius: 40px;
          background-color: #f6f5fe;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-upload-image {
            color: $primary-color;
          }

          .avatar {
            width: 88px;
            height: 88px;
            object-fit: cover;
            border-radius: 40px;
          }
        }

        .form-item {
          width: 100%;
          margin-bottom: 16px;
          
          .form-label-group {
            display: flex;
            margin-bottom: 8px;

            .form-label {
              font-size: 14px;
              font-weight: 500;
              margin-right: 4px;
            }
          }

          .field-group {
            width: 100%;

            .input {
              width: 100%;
              border: 0.5px solid $border-color;
              border-radius: 4px;
            }

            .disabled {
              background-color: #F5F7F6;
              color: #868E95;
            }

            .field-error {
              font-size: 12px;
              color: red;
              margin-top: 4px;
            }

            .field-note {
              color: #A8B3BD;
              font-size: 14px;
              font-weight: 400;
              margin-top: 4px;
            }
          }

          .phone-group {
            width: 100%;
            height: 44px;
            border: 1px solid $border-color;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .select-phone-code {
              width: 100px;
              border: none;

              button {
                border: none;
                width: 80px;
              }

              label {
                display: none;
              }

              div:has(> button) {
                min-width: 100px;
              }

              ul {
                min-width: 120px;
              }
            }

            .input-phone-group {
              width: calc(100% - 100px);
              .input-phone {
                border: none;
              }

              .input-phone:focus {
                outline: none;
              }
            }
            
          }
        }
      }
    }
  }

  .btn-group {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    position: fixed;
    bottom: 0;
    left: 0;

    .btn-save,
    .btn-cancel {
      width: 88px;
      height: 48px;
      border: 0.5px solid $primary-color;
    }

    .btn-save {
      background-color: $primary-color;
      color: #fff;
      margin-left: 12px;
    }

    .btn-cancel {
      background-color: #fff;
      color: $primary-color;
    }
  }
}
