@import "variables";

#pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  .paging {
    display: flex;
    list-style-type: none;
  }

  .paging {
    .icon-next-page {
      color: $primary-color;
    }
    
    li {
      a {
        width: 25px;
        height: 25px;
        border-radius: 4px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        color: #a8b3bd;
      }
    }

    .active {
      a {
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background-color: #e9ecff !important;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        color: $primary-color;
        font-weight: 600;
      }
    }
  }
}
