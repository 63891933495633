@import 'variables';

#organization-information-mobile {
  width: calc(100% - 16px);
  height: auto;
  margin: 8px auto;
  background-color: #fff;
  color: $primary-text-color;

  .box-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 0.5px solid #D9E1E9;

    .box-title {
      font-size: 16px;
      font-weight: 700;
    }

    .btn-edit {
      height: 40px;
      width: 40px;
      background-color: #F6F5FE;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    .icon-edit {
      color: $primary-color
    }
  }

  .box-body {
    padding: 0 16px;

    .box-sub-head {
      width: 100%;
      height: 44px;
      border-bottom: 0.5px solid #D9E1E9;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
    }

    .box-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #D9E1E9;
      padding: 16px 0;

      .brand-logo {
        max-width: 80%;
        object-fit: contain;
      }

      .image-default {
        width: 178px;
        height: 88px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F6F5FE;

        .icon-image-default {
          color: $primary-color;
        }
      }

      .organization-logo {
        width: 88px;
      }

      .icon-group {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FEEFE7;

        .icon {
          color: #F1600D;
        }
      }

      .box-item-right {
        width: calc(100% - 56px);

        .box-title-group {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .box-item-title {
            font-size: 14px;
            font-weight: 500;
            margin-right: 8px;
          }
        }

        .box-color {
          display: flex;
          align-items: center;

          .color-demo {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            margin-right: 13px;
          }

          .color-name {
            font-size: 16px;
            font-weight: 700;
            color: #000;

            span {
              color: #A8B3BD;
              margin-right: 4px;
            }
          }
        }

        .box-information {
          margin-bottom: 16px;
          .box-chip {
            width: 77px;
            height: 24px;
            border-radius: 44px;
            background-color: #F6F5FE;
            color: $primary-text-color;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .box-content {
            font-size: 14px;
          }
        }
      }
    }
  }
}