@import 'variables';

#organization-information {
  width: calc(100% - 32px);
  height: auto;
  background-color: #fff;
  margin: 16px;
  border-radius: 8px;
  padding: 16px 0;

  .box-head {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D9E1E9;
    padding: 0 24px;

    .box-title {
      font-size: 16px;
      font-weight: 700;
    }

    .btn-edit {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #081774;
      border: 1px solid #A7B4FE;
    }
  }

  .box-body {
    width: 100%;
    height: auto;

    .box-sub-head {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #D9E1E9;
      padding: 0 24px;
      font-size: 14px;
      font-weight: 700;
    }

    .box-item {
      width: 100%;
      height: auto;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #D9E1E9;

      .box-item-left {
        width: 300px;
        display: flex;
        align-items: center;

        .icon-group {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          background-color: #FEEFE7;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;

          .icon {
            color: #F1600D;
          }
        }

        .box-item-title {
          color: $primary-text-color;
          font-size: 14px;
          font-weight: 500;
          margin-right: 8px;
          user-select: none;
        }
      }

      .box-item-right {
        width: calc(100% - 350px);

        .cover-banner {
          width: 300px;
          object-fit: contain;
        }

        .image-default {
          width: 178px;
          height: 88px;
          background-color: #F6F5FE;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-image-default {
            color: $primary-color;
          }
        }

        .organization-logo {
          width: 88px;
          height: 88px;
        }

        .organization-logo {
          width: 100px;
          height: 100px;
        }

        .box-color {
          display: flex;
          align-items: center;

          .color-demo {
            width: 32px;
            height: 32px;
            border-radius: 4px;
            margin-right: 8px;
          }

          .color-name {
            font-size: 16px;
            font-weight: 700;
            color: #000;

            span {
              color: #A8B3BD;
              margin-right: 5px;
            }
          }
        }

        .box-information {
          margin-bottom: 16px;

          .box-chip {
            width: 77px;
            background-color: #E9ECFF;
            color: $primary-text-color;
            margin-bottom: 8px;
          }

          .box-content {
            font-size: 14px;
            font-weight: 400;
            color: #2B3033;
          }
        }

        .single-value {
          color: #2B3033;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}