@import "variables";

#breadcrumb {
  width: 100%;
  height: 48px;
  background-color: #fff;
  padding: 0 24px;
  display: flex;
  align-items: center;

  .icon-home, .name-route {
    color: #868E95;
    font-size: 12px;
  }

  .icon-home {
    width: 12px;
    height: 12px;
  }
}