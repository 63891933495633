@import 'variables';

#dialog-notification {
  .btn-cancel {
    border: 0.5px solid #e9ecff;
    color: $primary-color;
    background-color: #fff;
    height: 48px;
  }

  .btn-save {
    background-color: $primary-color;
    border: 0.5px solid $primary-color;
    color: #fff;
    margin-left: 12px;
    height: 48px;
  }
}