@import "variables";

#role-detail {
  width: calc(100% - 32px);
  height: auto;
  margin: 16px;
  background-color: #fff;
  border-radius: 4px;
  color: $primary-text-color;

  .role-information {
    width: 100%;

    .information-header {
      width: 100%;
      height: 64px;
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;

      .header-title {
        font-size: 16px;
        font-weight: 700;
      }

      .btn-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 1px solid #a6a1fd;
        border-radius: 4px;

        .icon-edit {
          color: $primary-color;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: $primary-color;
        }
      }
    }

    .information-body {
      width: 100%;
      height: auto;
      padding: 24px 24px 100px;

      .information-item {
        width: 100%;
        height: 72px;
        border-top: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-left {
          width: 20%;
          display: flex;
          align-items: center;

          .icon-group {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background-color: #feefe7;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;

            .icon {
              color: #f1600d;
            }
          }

          span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .item-right {
          width: 80%;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .information-item:first-child {
        border-top: none;
      }

      .access-permissions {
        width: 100%;
        height: auto;

        .permission-row {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px;
          background-color: #f6f5fe80;
          margin-top: 8px;

          .row-left {
            width: 30%;
            display: flex;
            align-items: center;

            .icon-permission {
              margin-right: 8px;
            }
          }

          .label {
            font-size: 14px;
            font-weight: 500;
          }

          .row-right {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .checkbox-item {
              width: calc(100% / 4);
              display: flex;
              align-items: center;

              .checkbox {
                border-radius: 4px;
              }
            }
          }
        }

        .row-child {
          border-top: 0.5px solid $border-color;
          margin-top: 0;

          .row-left {
            padding-left: 30px;
          }
        }
      }
    }
  }
}
