@import 'variables';

#attraction-attendees-mobile {
  width: 100%;
  color: $primary-text-color;

  .attendees-form {
    width: 100%;
    padding-bottom: 50px;

    .attendees-form__filter {
      .filter__header {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        background-color: #fff;
        border-top: 0.5px solid $border-color;
        border-bottom: 0.5px solid $border-color;

        .filter__header__label {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .attendees-form__body {
      width: calc(100% - 16px);
      margin: 16px 8px;

      .attendees-item {
        width: 100%;
        padding: 16px;
        border-radius: 8px;
        background-color: #fff;
        margin-bottom: 8px;

        .attendees-item__row {
          width: 100%;
          display: flex;
          align-items: flex-start;
          margin-bottom: 8px;

          .attendees-item__row__label {
            width: 112px;
            font-size: 14px;
            font-weight: 500;
          }
          
          .attendees-item__row__value {
            width: calc(100% - 112px);
            font-size: 14px;
          }

          .status {
            padding: 2px 16px;
            border-radius: 16px;
            font-weight: 500;
            font-size: 14px;
          }

          .created {
            background-color: #DCFCE7;
            color: #1D813F;
          }

          .using { 
            background-color: #FEF9C3;
            color: #A16207;
          }

          .out-of-usage {
            background-color: #F3E8FF;
            color: #7E22D7;
          }

          .expired {
            background-color: #FFCFDD;
            color: #9D0520;
          }
        }
      }
    }

    .overlay {
      filter: grayscale(100%) blur(3px);
      opacity: 1; /* Adjust the opacity as needed */
    }
  }
}

.popup-search-attendees {
  color: $primary-text-color;
  left: 8px !important;

  .filter-item {
    width: 100%;
    margin-bottom: 16px;

    .filter-item__label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .filter-item__input {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      border: 1px solid $border-color;
      font-size: 14px;
    }

    #date-range-custom {
      width: 100%;

      .input-date {
        width: 100%;
      }
    }
  }

  .btn-search, .btn-export {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #a6a1fd;
    color: $primary-color;

    .icon-search {
      color: $primary-color;
      margin-right: 4px;
    }
  }

  .btn-export {
    background-color: $primary-color;
    color: #fff;
    border: 1px solid $primary-color;
    margin-top: 16px;
  }
}
