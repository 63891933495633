@import "variables";

#profile {
  width: 100%;
  color: $primary-text-color;

  .form-profile {
    width: 100%;

    .form-info {
      width: calc(100% - 48px);
      margin: 24px 24px 0;
      border-radius: 4px;
      background-color: #fff;

      .form-header {
        width: 100%;
        height: 64px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;

        .header-label {
          font-size: 16px;
          font-weight: 700;
        }

        .btn-edit {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border: 1px solid #a7b4fe;
          border-radius: 4px;
          color: $primary-color;

          .icon-edit {
            color: $primary-color;
            margin-right: 5px;
          }
        }
      }

      .form-body {
        width: 100%;
        padding: 24px;
        
        .avatar-group {
          width: 88px;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          border: 1px solid $border-color;
          margin-bottom: 24px;

          .avatar {
            width: 88px;
            height: 88px;
            object-fit: cover;
            border-radius: 40px;
          }
        }

        .form-item {
          width: 100%;
          min-height: 72px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid $border-color;
          padding: 16px 0;

          .form-left {
            width: 30%;
            display: flex;
            align-items: center;

            .icon-group {
              width: 40px; 
              height: 40px;
              border-radius: 40px;
              background-color: #FEEFE7;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 16px;

              .icon {
                color: #F1600D;
              }
            }

            .label {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        .form-item:last-child {
          border-bottom: none;
        }
      }
    }

    .manage-password {
      width: calc(100% - 48px);
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      background-color: #fff;
      margin: 4px 24px 50px;
      padding: 0 24px;

      .label {
        font-size: 16px;
        font-weight: 700;
      }

      .btn-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 1px solid #a7b4fe;
        border-radius: 4px;
        color: $primary-color;

        .icon-edit {
          color: $primary-color;
          margin-right: 5px;
        }
      }
    }
  }
}
