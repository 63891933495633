@import "variables";

#order-detail-mobile {
  width: 100%;
  color: $primary-text-color;

  .order-form {
    width: 100%;

    .order-form__header {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-top: 0.5px solid $border-color;
      border-bottom: 0.5px solid $border-color;
      padding: 0 16px;

      .header__label {
        font-size: 16px;
        font-weight: 700;
      }

      .icon-list-btn {
        font-weight: 600;
        color: $primary-color;
      }
    }

    .order-form__body {
      width: 100%;
      padding: 8px 0;

      .body__item {
        width: calc(100% - 16px);
        border-radius: 8px;
        margin: 0 auto 8px;
        background-color: #fff;

        .body__item__header {
          width: 100%;
          height: 64px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          border-bottom: 0.5px solid $border-color;

          .body__item__header__label {
            font-size: 16px;
            font-weight: 700;
          }
        }

        .body__item__content {
          padding: 16px;

          .content__item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;

            .content__item__label {
              width: 100px;
              font-weight: 500;
            }

            .content__item__value {
              width: calc(100% - 112px);
              font-weight: 400;

              .send-email-payment-success {
                color: #16831C;
                margin-left: 4px;
              }
            }
          }
        }
      }

      .purchased-group {
        width: 100%;

        .body__item__content-label {
          font-size: 16px;
          font-weight: 700;
        }
      }

      .participant__form {
        width: 100%;

        .filter-group {
          width: 100%;
          padding: 16px;
          background-color: #fff;
          margin-top: -8px;
          border-top: 0.5px solid $border-color;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          .filter-group__label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }

          .filter-group__row {
            width: 100%;
            display: flex;
            align-items: center;

            .search-group__input {
              width: 200px;
              height: 40px;
              border-radius: 4px;
              margin-right: 16px;
              border: 1px solid $border-color;
            }

            .btn-search {
              width: calc(100% - 220px);
              height: 40px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #A6A1FD;
              color: $primary-color;
              background-color: #fff;

              .icon-search {
                color: $primary-color;
                margin-right: 4px;
              }
            }
          }
        }

        .participant-item {
          width: calc(100% - 16px);
          padding: 16px;
          background-color: #fff;
          border-radius: 8px;
          margin: 8px 8px 0;
          display: flex;

          .participant-item__avatar {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F6F5FE;

            .icon-avatar {
              color: #A7B4FE;
            }
          }

          .participant-item__information {
            width: calc(100% - 72px);
            margin: 0 8px;

            .information__username {
              font-size: 14px;
              font-weight: 500;
            }

            .information__email {
              font-size: 10px;
              font-weight: 400;
              margin: 8px 0;
            }

            .information__email-status {
              padding: 4px 12px;
              border-radius: 16px;
              font-size: 14px;
              font-weight: 500;
              max-width: 120px;
            }

            .sent {
              background-color: #DCFCE7;
              color: #1D813F;
            }

            .not-sent {
              background-color: #FFCFDD;
              color: #9D0520;
            }
          }

          .icon-email {
            width: 16px;
            height: 16px;
            color: $primary-color;
          }
        }

        .attendant-bottom {
          margin-bottom: 16px;
        }
      }

      .purchased__list {
        width: calc(100% - 16px);
        margin: 8px auto;

        .purchased__item {
          width: 100%;
          padding: 16px;
          background-color: #fff;
          margin-bottom: 8px;
          border-radius: 8px;

          .purchased__item__row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;

            .purchased__item__label {
              width: 100px;
              font-weight: 500;
            }

            .purchased__item__value {
              width: calc(100% - 112px);
              font-weight: 400;
            }
          }
        }
      }
    }

    .overlay {
      filter: grayscale(100%) blur(3px);
      opacity: 1; /* Adjust the opacity as needed */
    }
  }

  .select-phone-code {
    width: 100px;
    border: none;

    button {
      border: none;
      width: 80px;
    }

    label {
      display: none;
    }

    div:has(> button) {
      min-width: 100px;
    }

    ul {
      min-width: 120px;
    }
  }

  .input-phone {
    width: 100%;
    border: none;
  }

  .input-phone:focus {
    outline: none;
    --tw-ring-color: none;
  }
}

#popover-list-action {
  width: calc(100% - 16px);
  left: 8px !important;

  .btn-resend {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;

    .icon-email {
      color: #fff;
      margin-right: 8px;
    }
  }
}
