@import "variables";

#role-detail-mobile {
  width: 100%;
  color: $primary-text-color;
  padding-bottom: 100px;

  .role-information {
    width: calc(100% - 32px);
    margin: 16px;
    background-color: #fff;
    border-radius: 4px;

    .information-header {
      width: 100%;
      height: 64px;
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      .header-title {
        font-size: 16px;
        font-weight: 700;
      }

      .btn-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #F6F5FE;
        border-radius: 4px;
        background-color: #fff;

        .icon-edit {
          color: $primary-color;
        }
      }
    }

    .information-body {
      width: 100%;
      padding: 24px 16px;

      .information-item {
        width: 100%;
        height: 72px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .item-left {
          display: flex;
          
          .icon-group {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FEEFE7;
            margin-right: 12px;
            border-radius: 40px;

            .icon {
              color: #F1600D;
            }
          }

          span {
            font-weight: 500;
          }
        }

        .item-right {
          margin-left: 44px;
        }
      }

      .information-item:not(:first-child) {
        border-top: 1px solid $border-color;
      }

      .access-permissions {
        width: 100%;

        .permission-row {
          width: 100%;
          justify-content: space-between;
          padding: 8px;
          background-color: #f6f5fe80;
          margin-top: 8px;
          border-radius: 4px;
          
          .row-left {
            margin-bottom: 16px;
            display: flex;
            align-items: center;

            .icon-permission {
              margin-right: 8px;
            }
          }

          .row-right {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .checkbox-item {
              width: calc(50% - 5px);
              display: flex;
              align-items: center;
              margin-bottom: 8px;
            }
          }
        }

        .row-child {
          border-top: 0.5px solid $border-color;
          margin-top: 0;

          .row-left {
            padding-left: 30px;
          }
        }
      }
    }
  }
}