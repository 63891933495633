@import "variables";

#dashboard {
  width: 100%;
  color: $primary-text-color;
  padding-bottom: 100px;

  .dashboard-body {
    width: calc(100% - 32px);
    margin: 24px 16px;

    .dashboard-tab {
      width: 100%;
      height: 40px;
      display: flex;

      .tab-item {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #718ebf;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      .current-tab {
        color: #1814f3;
        border-bottom: 3px solid #1814f3;
      }
    }

    .overview {
      width: 100%;
      margin-top: 16px;

      .overview-board {
        width: 100%;
        height: 268px;
        border-radius: 8px;
        background-color: #a7b4fe;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .filter-group {
          width: 435px;
          height: 68px;
          margin-right: 22px;

          .filter-label {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 8px;
          }

          .date-picker {
            #date-range-custom {
              background-color: #fff;
            }
          }
        }

        .carousel-list-item {
          width: 100%;
          display: flex;
          align-items: center;

          .icon-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            width: 40px;
            height: 40px;
            border: 1px solid #A7B4FE;
            border-radius: 40px;
            position: absolute;
            z-index: 100;
            
            .arrow {
              color: $primary-color;
            }
          }

          .next-arrow {
            right: 10px;
          }

          .prev-arrow {
            margin-left: -15px;
          }
        }

        .carousel {
          width: 100%;
          margin-top: 16px;
          padding: 0 22px;
          position: relative;

          span {
            display: none;
          }

          .carousel-list {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 18px;

            .carousel-item {
              width: calc(25% - 7.5px);
              height: 135px;
              border-radius: 8px;
              background-color: #fff;
              padding: 12px 15px;
              display: flex;
              justify-content: space-between;
              margin: 0 8px;

              .carousel-icon-group {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .group-revenue {
                background-color: #ddfdcd;
              }

              .total-orders {
                background-color: #ffe0eb;
              }

              .total-tickets {
                background-color: #e7edff;
              }

              .total-participants {
                background-color: #fff5d9;
              }

              .total-discount {
                background-color: #fff5d9;
              }

              .total-promotion {
                background-color: #feefe7;
              }

              .net-revenue {
                background-color: #e2f5f5;
              }

              .carousel-info {
                width: calc(100% - 58px);

                .info-label {
                  display: flex;
                  font-size: 14px;
                  font-weight: 400;
                  color: #718ebf;
                  margin-bottom: 4px;

                  span {
                    max-width: calc(100% - 24px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .icon-tooltip {
                    margin-left: 4px;
                    width: 14px;
                    height: 14px;
                  }
                }

                .info-main-value {
                  font-size: 20px;
                  font-weight: 700;
                  margin-bottom: 4px;
                  cursor: pointer;

                  .full-number {
                    display: none;
                  }
                }

                .info-main-value:hover {
                  .shorten {
                    display: none;
                  }

                  .full-number {
                    display: inline;
                  }
                }

                .fluctuations {
                  display: flex;
                  font-size: 12px;

                  .fluctuation-percent {
                    display: flex;
                    align-items: flex-start;
                    font-weight: 600;
                    margin-right: 4px;

                    .icon-down {
                      color: #f87171;
                      margin-top: 3px;
                    }

                    .icon-up {
                      color: #16831c;
                      margin-top: 3px;
                    }
                  }
                }

                .reduce {
                  color: #f87171;
                }

                .increase {
                  color: #16831c;
                }
              }

              .view-more {
                font-size: 12px;
                font-weight: 600;
                color: $primary-color;
                cursor: pointer;
                margin-top: 4px;
              }

              .view-more:hover {
                text-decoration: underline;
              }
            }
          }

          .btn-next {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background-color: #fff;
            border: 1px solid #a7b4fe;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .carousel-item {
          width: 100%;
          height: 135px;
          border-radius: 8px;
          background-color: #fff;
          padding: 12px 15px;
          display: flex;
          justify-content: space-between;

          .carousel-icon-group {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .group-revenue {
            background-color: #ddfdcd;
          }

          .total-orders {
            background-color: #ffe0eb;
          }

          .total-tickets {
            background-color: #e7edff;
          }

          .total-participants {
            background-color: #fff5d9;
          }

          .total-discount {
            background-color: #fff5d9;
          }

          .total-promotion {
            background-color: #feefe7;
          }

          .net-revenue {
            background-color: #e2f5f5;
          }

          .carousel-info {
            width: calc(100% - 58px);

            .info-label {
              display: flex;
              font-size: 14px;
              font-weight: 400;
              color: #718ebf;
              margin-bottom: 4px;

              span {
                max-width: calc(100% - 24px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .icon-tooltip {
                margin-left: 4px;
                width: 14px;
                height: 14px;
              }
            }

            .info-main-value {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 4px;
              cursor: pointer;

              .full-number {
                display: none;
              }
            }

            .info-main-value:hover {
              .shorten {
                display: none;
              }

              .full-number {
                display: inline;
              }
            }

            .fluctuations {
              display: flex;
              font-size: 12px;

              .fluctuation-percent {
                display: flex;
                align-items: flex-start;
                font-weight: 600;
                margin-right: 4px;

                .icon-down {
                  color: #f87171;
                  margin-top: 3px;
                }

                .icon-up {
                  color: #16831c;
                  margin-top: 3px;
                }
              }
            }

            .reduce {
              color: #f87171;
            }

            .increase {
              color: #16831c;
            }
          }

          .view-more {
            font-size: 12px;
            font-weight: 600;
            color: $primary-color;
            cursor: pointer;
            margin-top: 4px;
          }

          .view-more:hover {
            text-decoration: underline;
          }
        }
      }

      .events-board {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        margin: 8px 0;

        .events-header {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 22px;
          border-bottom: 0.5px solid #e9ecff;

          .header-label {
            font-size: 16px;
            font-weight: 700;
            width: 100px;
          }

          .filters {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .filter-label {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 4px;
            }

            .filter-event {
              width: calc(60% - 85px);
              max-width: 400px;
              margin-right: 12px;
            }

            .filter-status {
              width: calc(40% - 85px);
              max-width: 208px;
            }

            .btn-export {
              width: 146px;
              height: 38px;
              border-radius: 4px;
              border: 1px solid #a6a1fd;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: $primary-color;
              margin-top: 20px;

              .icon-export {
                color: $primary-color;
                margin-right: 4px;
              }

              span {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .events-body {
          width: 100%;
          padding: 24px 22px;
          font-size: 14px;

          table {
            width: 100%;

            thead {
              background-color: #e9ecff;
              width: 100%;

              .table-label {
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                display: flex;
                align-items: center;
              }
            }

            .leading-normal {
              font-weight: 400;
              color: #2b3033;
            }

            .event-name {
              cursor: pointer;
              width: 222px;
            }
  
            .event-name:hover {
              color: $primary-color;
              font-weight: 500;
            }

            .table-row {
              border-bottom: 1px solid #D9E1E9;
            }

            .table-row:hover {
              background-color: #F6F5FE;
            }

            .table-head {
              padding: 16px 0;
            }

            .stt {
              width: 40px;
              padding-left: 16px;
            }
  
            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100px;
              font-size: 12px;
              font-weight: 600;
              padding: 4px 12px;
              max-width: 96px;
            }
  
            .opening {
              background-color: #fef9c3;
              color: #a16207;
            }
  
            .closed {
              background-color: #ffdaef;
              color: #be189c;
            }
  
            .coming {
              background-color: #f3e8ff;
              color: #7e22d7;
            }

            .table-content-amount {
              max-width: 98px;
              padding: 16px 2px;
            }
          }
        }

        .board__bottom {
          #pagination {
            justify-content: flex-end;
          }
        }
      }

      .chart-board {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;

        .chart-header {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 22px;
          border-bottom: 0.5px solid $border-color;

          .header-label {
            display: flex;
            font-size: 16px;
            font-weight: 700;
            align-items: center;

            .icon-tooltip {
              margin-left: 4px;
            }
          }

          .filters {
            width: 435px;

            .label {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 4px;
            }
          }
        }

        .chart-body {
          padding: 24px 22px;
          font-size: 14px;
        }
      }
    }

    #event-tab {
      width: calc(100% - 32px);
      margin: 16px;

      .filter-event {
        width: 100%;
        padding: 16px 24px;
        border-radius: 8px;
        background-color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .filter-event__item {
          width: calc(50% - 6px);
          max-width: 544px;

          .filter-event__item__label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }

          #date-range-custom {
            width: 100%;

            .input-date {
              width: 100%;
            }
          }
        }

        .date {
          width: 230px;
          margin-left: 12px;
        }
      }

      .ticket-group {
        width: 100%;
        padding: 16px 24px;
        background-color: #fff;
        border-radius: 8px;

        .ticket-group__header {
          width: 100%;
          height: 40px;
          border-bottom: 1px solid #e9ecff;
          display: flex;

          .ticket-group__header__tab {
            width: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #718EBF;
            cursor: pointer;
            font-weight: 500;
          }

          .tab-active {
            color: #1814F3;
            border-bottom: 2px solid #1814F3;
          }
        }

        .ticket-group__filter {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin: 16px 0;

          .ticket-group__filter-tickets,
          .ticket-group__filter-tickets-phase {
            width: calc(50% - 6px);
            margin-right: 12px;
            margin-top: 10px;

            .filter-tickets__label {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
            }
          }

          .ticket-group__filter-tickets-phase {
            width: calc(30% - 6px);
          }

          .btn-export {
            width: calc(20% - 12px);
            height: 38px;
            border: 1px solid #a6a1fd;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary-color;
            cursor: pointer;
            background-color: #fff;
            border-radius: 4px;
            margin-top: 28px;
            font-size: 14px;

            .icon-export {
              color: $primary-color;
              margin-right: 4px;
            }
          }

          .filter-group__row {
            width: 100%;
            display: flex;
            margin-bottom: 16px;
            align-items: flex-end;

            .group-summary {
              width: calc(25% - 6px);
              padding: 11px;
              border-radius: 4px;
              border: 0.5px solid $border-color;
              display: flex;

              .group-summary__icon-group {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                background-color: #e7edff;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .total-member {
                background-color: #ddfdcd;
              }

              .total-group-payment {
                background-color: #def6ff;
              }

              .total-member-payment {
                background-color: #e2f5f5;
              }

              .group-summary__info {
                width: calc(100% - 52px);
                margin-left: 12px;

                .group-summary__info__label {
                  color: #718ebf;
                  height: 42px;
                  font-size: 14px;
                  margin-bottom: 8px;
                }

                .group-summary__info__value {
                  font-size: 20px;
                  font-weight: 700;
                }
              }
            }

            .group-summary:not(:first-child) {
              margin-left: 12px;
            }

            .ticket-group__filter-group {
              width: calc(40% - 85px);
              margin-right: 12px;

              .filter-tickets__label {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 8px;
              }
            }
          }
        }

        .ticket-group__board {
          color: #2B3033;
          
          .table-row {
            border-bottom: 1px solid #D9E1E9;
          }

          .table-row:hover {
            background-color: #F6F5FE;
          }

          .table-value {
            font-weight: 400;
          }

          .board-column {
            width: calc(16% - 45px);
            padding: 4px;
          }

          .board-column-head {
            width: calc(16% - 45px);
            padding: 16px 4px;
          }

          .stt {
            width: 40px;
            padding: 16px;
          }
        }
      }

      .info-group {
        width: 100%;
        border-radius: 4px;
        background-color: #fff;
        margin-top: 16px;

        .info-group__header {
          border-bottom: 0.5px solid #e9ecff;
          height: 64px;
          display: flex;
          align-items: center;

          .info-group__title {
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            margin-left: 24px;
          }
        }

        .info-group__body {
          padding: 24px;

          .info-group__filter {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 24px;

            .filter-categories {
              width: calc(30% - 30px);

              .filter-categories__label {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 8px;
              }
            }
          }

          .info-group__board {
            width: 100%;
            
          }
        }
      }

      table {
        thead {
          background-color: #e9ecff;

          .table-label {
            font-size: 14px;
            font-weight: 500;

            display: flex;
            align-items: center;

            .icon-tooltip {
              margin-left: 4px;
            }
          }
        }

        .table-row {
          border-bottom: 1px solid #D9E1E9;
        }

        .table-row:hover {
          background-color: #F6F5FE;
        }

        .table-link {
          color: $primary-color;
          cursor: pointer;
        }

        .group-status {
          padding: 4px 12px;
          border-radius: 12px;
          font-size: 14px;
          font-weight: 600;
        }

        .opening {
          background-color: #fef9c3;
          color: #a16207;
        }

        .sending {
          background-color: #f3e8ff;
          color: #7e22d7;
        }

        .closed {
          background-color: #ffdaef;
          color: #be189c;
        }

        .success {
          background-color: #dcfce7;
          color: #1d813f;
        }
      }

      .board__bottom {
        #pagination {
          justify-content: flex-end;
        }
      }
    }

    .select__input-container {
      font-size: 14px;
    }
  }
}
