@import "variables";

#account-management {
  width: 100%;
  color: $primary-text-color;

  #pagination {
    justify-content: flex-end;
  }

  .header-page {
    width: 100%;
    height: auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 12px 40px;

    .header-title {
      font-size: 20px;
      font-weight: 700;
    }

    .btn-create-account {
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;

      .text-base {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .page-body {
    width: 100%;
    height: auto;
    padding: 24px;

    .filter-group {
      width: 100%;
      height: auto;
      padding: 24px;
      background-color: #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .filter-item {
        width: calc((100% - 167px) / 4);
        min-width: 230px;
        margin-right: 8px;
        margin-bottom: 8px;

        .filter-label {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 4px;
        }

        .input-search {
          width: 100%;
          border-radius: 4px;
          border: 1px solid $border-color;
          font-size: 14px;
        }

        .select-role {
          border-radius: 4px;
          border: 1px solid $border-color;

          button {
            border: none;
          }

          label {
            display: none;
          }

          div:has(> button) {
            min-width: 100%;
          }
        }
      }

      .date-picker {
        #date-range-custom {
          .input-date {
            width: 100%;
            height: 40px;
          }
        }
      }

      .btn-search {
        width: 143px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #a6a1fd;
        color: $primary-color;
        background-color: #fff;
        margin-top: 15px;

        .icon-search {
          color: $primary-color;
        }

        .btn-title {
          font-size: 14px;
          font-weight: 400;
          text-transform: capitalize;
        }
      }
    }

    table {

      thead {
        background-color: #f6f5fe;

        p {
          color: $primary-text-color;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .table-row {
        border-bottom: 1px solid #d9e1e9;
      }

      .table-row:hover {
        background-color: #f6f5fe;
      }

      .content-table {
        max-width: 100px;
        padding: 16px 4px;
        word-wrap: break-word;
        color: #2B3033;
      }

      .stt {
        padding-left: 16px !important;
      }
    }

    .dashboard {
      width: 100%;
      height: auto;
      padding: 24px;
      background-color: #fff;
      border-radius: 4px;
      margin: 12px 0;

      .switch {
        background-image: none;
      }

      .action {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn-detail {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background-color: #f5f6f7;
          cursor: pointer;
        }

        .btn-detail:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}
