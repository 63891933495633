@import "variables";

#date-picker-chart {
  color: $primary-text-color;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $border-color;
  padding-right: 10px;

  .input-date {
    width: 435px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    background-color: #fff;

    .placeholder {
      font-size: 14px;
      color: #c5ccd2;
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .to-date {
        display: flex;

        .icon-arrow-calendar {
          margin-right: 10px;
        }
      }
    }

    .calendar-chart {
      color: #c5ccd2;
    }

    .date-value {
      display: flex;
      font-size: 14px;
    }
  }
}

.popup-date-picker {
  width: 435px;
  display: flex;
  z-index: 1000;
  justify-content: space-between;

  .date-type {
    width: 135px;
    border-right: 0.5px solid #e9ecff;

    .type-default {
      width: calc(100% - 14px);
      border-bottom: 0.5px solid #e9ecff;
      margin-bottom: 14px;

      .type-item {
        color: $primary-text-color;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .icon-chevron {
          color: $primary-text-color;
        }

        .type-selected {
          color: $primary-color;
          font-weight: 600;
        }
      }
    }

    .type-default:last-child {
      border-bottom: none;
    }
  }

  .calendar-chart {
    .react-datepicker {
      border: none;
      font-family: "Roboto" !important;
    }

    .react-datepicker__month-container {
      width: 260px;

      .react-datepicker__month-wrapper {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .react-datepicker__month-text {
          font-size: 13px;
          font-weight: 400;
          padding: 2px 0;
        }

        .react-datepicker__month-text--keyboard-selected {
          background-color: $primary-color;
          color: #fff;
          font-weight: 600;
        }
      }
    }

    .react-datepicker__quarter-wrapper {
      display: flex;
      justify-content: space-between;

      .react-datepicker__quarter-text {
        width: 24%;
        padding: 2px 0;
      }

      .react-datepicker__quarter-text--keyboard-selected {
        background-color: $primary-color;
        color: #fff;
        font-weight: 600;
      }
    }

    .react-datepicker__year--container {
      width: 260px;

      .react-datepicker__year-wrapper {
        max-width: 260px;
        display: flex;
        justify-content: space-between;

        .react-datepicker__year-text {
          padding: 2px 0;
        }

        .react-datepicker__year-text--selected {
          background-color: $primary-color;
          font-weight: 600;
        }
      }
    }

    .react-datepicker__header {
      background-color: #fff;
      border-bottom: 1px solid $border-color;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      background-color: #e9ecff;
      color: $primary-color;
      font-weight: 400;
    }

    .react-datepicker__day--selected, .react-datepicker__day--range-end {
      background-color: $primary-color;
      color: #fff;
      font-weight: 600;
    }

    .react-datepicker__day--keyboard-selected:last-child {
      background-color: #fff;
      color: #000;
    }

    .react-datepicker__year-text {
      width: calc(50% - 5px);
    }
  }
}

.popup-date-picker__mobile {
  width: calc(100% - 32px);
  flex-direction: column;

  .date-type {
    border-right: 0;
    border-bottom: 0.5px solid #e9ecff;
    width: 100%;
  }

  .type-default {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .calendar-chart {
    .react-datepicker {
      width: 100%;

      .react-datepicker__month-container,
      .react-datepicker__year--container {
        width: 100%;

        .react-datepicker__header {
          width: 100%;
        }

        .react-datepicker__month {
          width: 100%;
        }

        .react-datepicker__year {
          width: 100%;

          .react-datepicker__year-wrapper {
            max-width: 100%;
          }
        }
      }
    }
    
    .react-datepicker__year-text {
      width: calc(50% - 5px);
    }
  }
}

.react-datepicker__navigation--previous:focus {
  border: none !important;
  outline: none !important;
}
