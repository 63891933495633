@import 'variables';

#attraction-attendees-detail {
  width: 100%;
  color: $primary-text-color;

  .attendees-detail-form {
    width: 100%;

    .detail-form__header {
      width: 100%;
      height: 64px;
      padding: 0 40px;
      font-size: 20px;
      font-weight: 700;
      background-color: #fff;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
    }

    .detail-form__body {
      width: calc(100% - 32px);
      margin: 16px;

      .form-item {
        width: 100%;
        border-radius: 4px;
        background-color: #fff;
        margin-bottom: 16px;

        .form-item__header {
          width: 100%;
          height: 40px;
          border-bottom: 1px solid $border-color;
          display: flex;
          align-items: center;

          .form-item__header__label {
            font-size: 16px;
            font-weight: 700;
            margin-left: 16px;
          }
        }

        .form-item__body {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          .form-item__information {
            width: calc(50% - 40px);
            margin-bottom: 16px;
            font-size: 14px;
            display: flex;

            .form-item__information__label {
              width: 188px;
              font-weight: 500;
            }

            .form-item__information__value {
              width: calc(100% - 200px);
              font-weight: 400;
              margin-left: 12px;

              .status {
                width: 118px;
                padding: 4px 0;
                border-radius: 12px;
                font-weight: 500;
                display: flex;
                justify-content: center;
              }

              .active {
                background-color: #DCFCE7;
                color: #1D813F;
              }

              .in-active {
                background-color: #FFCFDD;
                color: #9D0520;
              }
            }

            .order-code {
              color: $primary-color;
              cursor: pointer;
            }
          }

          .form-item__group {
            width: 100%;
            margin-bottom: 16px;

            .group-name {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 16px;
            }

            .fields {
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}