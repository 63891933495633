@import "variables";

#profile-mobile {
  width: 100%;
  color: $primary-text-color;

  .form-profile {
    width: 100%;

    .form-info {
      width: calc(100% - 16px);
      margin: 16px 8px;
      background-color: #fff;
      border-radius: 4px;

      .form-header {
        width: 100%;
        height: 68px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        .header-label {
          font-size: 16px;
          font-weight: 600;
        }

        .btn-edit {
          border: 4px;
          border: 0.5px solid #d9e1e9;
          background-color: #fff;

          .icon-edit {
            color: $primary-color;
          }
        }
      }

      .form-body {
        width: 100%;
        padding: 16px;

        .avatar-group {
          margin-bottom: 16px;
          .avatar {
            width: 66px;
            height: 66px;
            border-radius: 40px;
            object-fit: cover;
          }
        }

        .form-item {
          width: 100%;
          min-height: 72px;
          height: auto;
          border-bottom: 0.5px solid $border-color;
          padding: 16px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .form-left {
            display: flex;

            .icon-group {
              width: 40px;
              height: 40px;
              border-radius: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #FEEFE7;
              margin-right: 16px;

              .icon {
                color: #F1600D;
              }
            }
            
            .label {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .form-right {
            margin-left: 56px;
            margin-top: -15px;
          }
        }
        
      }
    }

    .manage-password {
      width: calc(100% - 16px);
      height: 68px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      margin: 0 8px;
      border-radius: 4px;

      .label {
        font-size: 16px;
        font-weight: 600;
      }

      .btn-edit {
        border: 4px;
        border: 0.5px solid #d9e1e9;
        background-color: #fff;

        .icon-edit {
          color: $primary-color;
        }
      }
    }
  }
}
