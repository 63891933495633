@import 'variables';

#reset-password-mobile {
  width: 100%;
  min-height: 100vh;
  background: conic-gradient(from 5deg at -8.78% 126%, #FDF7F4 0.2740371460095048deg, #FAF1F8 4.876883961260319deg, #F4F2FE 9.16059672832489deg, #ECF5FE 20.33330887556076deg, #EBF4FD 36.175250709056854deg, #EFFBF2 58.38791370391846deg);
  display: flex;
  align-items: center;
  justify-content: center;

  .form {
    width: 90%;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px 24px;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      
      .logo {
        width: 89px;
        height: 40px;
      }

      .dropdown {
        border: 1px solid $border-color;
        border-radius: 16px;
        padding: 0 8px;

        button:focus {
          border: none;
          --tw-ring-color: none;
        }

        ul {
          margin-left: -8px;
        }
      }
    }

    .label {
      color: #19141E;
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    .form-group {
      width: 100%;

      .form-item {
        width: 100%;
        margin-bottom: 16px;

        .form-label {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .input {
          width: 100%;
          height: 42px;
          border: 1px solid #C5CCD2;
          border-radius: 4px;
          padding: 0 10px; 
        }

        .field-error {
          font-size: 12px;
          color: #DF4941;
          margin-top: 8px;
        }

        .icon-show-pass {
          position: absolute;
          right: calc(10% + 10px);
          margin-top: -33px;
          width: 16px;
        }

        .field-group {
          .pass-group {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            svg {
              position: absolute;
              margin-right: 10px;
            }
          }
        }
      }

      .btn-submit {
        width: 100%;
        height: 62px;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: capitalize;
        font-size: 19px;
        font-weight: 700;
        border-radius: 4px;
        margin-top: 24px;
        cursor: pointer;
      }
    }

    .btn-back-group {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      color: #A1A5AC;
      margin-top: 40px;

      .btn-back {
        color: $primary-color;
        margin-left: 5px;
      }
    }
  }
}

.link-invalid-mobile {
  width: 100vw;
  height: 100vh;
  background-color: #F6F5FE;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5vw;

  .logo-company {
    width: 134px;
    height: 60px;
    margin-top: 195px;
  }

  .link-invalid-title {
    margin: 18px 0 50px;
    color: #262626;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .link-invalid-content {
    font-size: 20px;
    font-weight: 400;
    color: #4F5559;
    margin-bottom: 50px;
    text-align: center;
  }

  .btn-forgor-password {
    width: 143px;
    height: 48px;
    border-radius: 4px;
    color: #fff;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px;
  }
}
