@import "variables";

#dashboard-mobile {
  width: 100%;
  color: $primary-text-color;
  padding-bottom: 100px;

  .dashboard-body {
    width: 100%;

    .filter-item__select {
      font-size: 14px;
    }

    .dashboard-tab {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px 0;

      .tab-item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #718ebf;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      .current-tab {
        color: #1814f3;
        border-bottom: 3px solid #1814f3;
      }
    }

    .overview {
      width: 100%;

      .overview-board {
        width: 100%;
        padding: 25px 16px;
        background-color: #a7b4fe;

        .filter-group {
          margin-bottom: 16px;
          .filter-label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }

          .date-picker {
            width: 100%;

            #date-range-custom {
              background-color: #fff;
              .input-date {
                width: 100%;
              }
            }
          }
        }

        .carousel-page {
          width: 100%;
          padding-bottom: 8px;

          .carousel-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            .carousel-item {
              width: calc(50% - 4px);
              padding: 20px 12px;
              background-color: #fff;
              border-radius: 8px;

              .carousel-icon-group {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .group-revenue {
                background-color: #ddfdcd;
              }

              .total-orders {
                background-color: #ffe0eb;
              }

              .total-tickets {
                background-color: #e7edff;
              }

              .total-participants {
                background-color: #fff5d9;
              }

              .total-discount {
                background-color: #fff5d9;
              }

              .total-promotion {
                background-color: #feefe7;
              }

              .net-revenue {
                background-color: #e2f5f5;
              }

              .carousel-info {
                margin-top: 8px;
                .info-label {
                  width: 100%;
                  display: flex;
                  font-size: 14px;
                  font-weight: 400;
                  color: #718ebf;
                  align-items: flex-start;
                }

                .icon-tooltip {
                  margin-left: 4px;
                }

                .info-main-value {
                  font-size: 20px;
                  font-weight: 700;
                  margin: 8px 0;
                }

                .fluctuations {
                  display: flex;
                  font-size: 12px;

                  .fluctuation-percent {
                    display: flex;
                    align-items: flex-start;
                    font-weight: 600;
                    margin-right: 4px;

                    .icon-down {
                      color: #f87171;
                      margin-top: 3px;
                    }

                    .icon-up {
                      color: #16831c;
                      margin-top: 3px;
                    }
                  }
                }

                .reduce {
                  color: #f87171;
                }

                .increase {
                  color: #16831c;
                }
              }

              .view-more {
                font-size: 12px;
                font-weight: 600;
                color: $primary-color;
                cursor: pointer;
                margin-top: 4px;
              }
            }
          }
        }

        .dot:first-child {
          margin-right: 8px;
        }

        .dot-active,
        .dot-inactive {
          width: 45px;
          height: 6px;
          border-radius: 8px;
          background-color: #fff;
          margin: 0;
        }

        .dot-inactive {
          background-color: #cfd6ff;
        }

        .react-multi-carousel-dot-list {
          margin-top: 8px;
        }
      }
    }

    .events-board {
      width: 100%;
      margin: 16px 0;

      .events-header {
        width: 100%;
        background-color: #fff;

        .header-label {
          width: 100%;
          height: 64px;
          border-bottom: 0.5px solid $border-color;
          display: flex;
          align-items: center;
          padding: 0 16px;
          font-size: 16px;
          font-weight: 700;
        }

        .filters {
          width: 100%;
          padding: 16px;

          .filter-event,
          .filter-status {
            width: 100%;
            margin-bottom: 16px;

            .filter-label {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
            }
          }

          .btn-export {
            width: 100%;
            height: 40px;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #a6a1fd;
            color: $primary-color;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-export {
              color: $primary-color;
              margin-right: 4px;
            }
          }
        }
      }

      .events-body {
        width: 100%;
        margin-top: 16px;

        .event-list {
          padding: 0 16px;
          .event-item {
            width: 100%;
            padding: 16px;
            background-color: #fff;
            margin-bottom: 16px;

            .event-item__row {
              width: 100%;
              margin-bottom: 16px;
              display: flex;
              font-size: 14px;

              .row-label {
                font-weight: 500;
                width: 134px;
              }

              .row-value {
                width: calc(100% - 134px);

                .status {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px 10px;
                  border-radius: 100px;
                  width: 126px;
                  height: 24px;
                  font-weight: 600;
                }

                .opening {
                  background-color: #fef9c3;
                  color: #a16207;
                }

                .closed {
                  background-color: #ffdaef;
                  color: #be189c;
                }

                .coming {
                  background-color: #f3e8ff;
                  color: #7e22d7;
                }
              }
            }
          }
        }
      }
    }

    .chart-board {
      width: 100%;
      margin-top: 16px;
      background-color: #fff;

      .chart-header {
        .header-label {
          width: 100%;
          height: 64px;
          display: flex;
          align-items: center;
          border-bottom: 0.5px solid $border-color;
          padding: 0 16px;
          font-size: 16px;
          font-weight: 700;

          .icon-tooltip {
            margin-left: 4px;
          }
        }

        .filters {
          margin-top: 8px;
          padding: 0 16px;

          .date-picker {
            width: 100%;

            #date-range-custom {
              .input-date {
                width: 100%;
              }
            }
          }
        }
      }
    }

    #event-tab {
      width: 100%;

      .filter-event {
        width: 100%;
        padding: 16px;
        background-color: #fff;
        margin-bottom: 16px;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        .filter-event__item {
          width: 100%;
          margin-bottom: 8px;

          .filter-event__item__label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
          }

          .filter-item__select {
            font-size: 14px;
          }

          #date-range-custom {
            .input-date {
              width: 100%;
            }
          }
        }
      }

      .ticket-group {
        width: 100%;

        .ticket-group__header {
          width: 100%;
          height: 40px;
          border-bottom: 0.5px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;

          .ticket-group__header__tab {
            width: 50%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #718EBF;
          }

          .tab-active {
            border-bottom: 2px solid #1814F3;
            color: #1814F3;
          }
        }

        .ticket-group__filter {
          width: 100%;
          padding: 16px;
          background-color: #fff;

          .ticket-group__filter-tickets,
          .ticket-group__filter-tickets-phase {
            margin-bottom: 8px;

            .filter-tickets__label {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 4px;
            }
          }

          .filter-group__row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .group-summary {
              width: calc(50% - 6px);
              min-height: 56px;
              padding: 8px;
              border-radius: 4px;
              margin-bottom: 8px;
              border: 1px solid $border-color;
              display: flex;
              justify-content: space-between;

              .group-summary__icon-group {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #def6ff;
              }

              .total-member {
                background-color: #ddfdcd;
              }

              .group-summary__info {
                width: calc(100% - 48px);

                .group-summary__info__label {
                  width: 100%;
                  height: 36px;
                  font-size: 12px;
                  font-weight: 500;
                }

                .group-summary__info__value {
                  width: 100%;
                  font-size: 12px;
                }
              }
            }

            .ticket-group__filter-group {
              width: calc(50% - 6px);

              .filter-tickets__label {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 4px;
              }
            }

            .btn-export-group {
              width: 100%;
              margin-top: 10px;
            }
          }
        }

        .ticket-group__board {
          width: 100%;
          padding: 16px;

          .ticket-group__item,
          .group-statistical-item {
            width: 100%;
            padding: 16px;
            border-radius: 4px;
            margin-bottom: 8px;
            background-color: #fff;

            .ticket-group__item-row,
            .group-statistical__row {
              width: 100%;
              margin-bottom: 8px;
              display: flex;

              .ticket-group__item-label,
              .group-statistical__label {
                font-size: 14px;
                font-weight: 500;
                width: 100px;
                display: flex;
              }

              .ticket-group__item-value,
              .group-statistical__value {
                font-size: 14px;
                font-weight: 400;
                width: calc(100% - 112px);
                margin-left: 12px;

                .group-status {
                  padding: 4px 12px;
                  border-radius: 12px;
                  font-size: 14px;
                }

                .opening {
                  background-color: #fef9c3;
                  color: #a16207;
                }

                .sending {
                  background-color: #f3e8ff;
                  color: #7e22d7;
                }

                .closed {
                  background-color: #ffdaef;
                  color: #be189c;
                }

                .success {
                  background-color: #dcfce7;
                  color: #1d813f;
                }
              }
            }
          }
        }
      }

      thead {
        background-color: #e9ecff;
        .table-label {
          font-size: 14px;
          font-weight: 500;

          display: flex;
          align-items: center;
        }
      }

      .info-group {
        width: 100%;

        .info-group__header {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          background-color: #fff;
          padding: 16px;
          border-bottom: 1px solid $border-color;

          .info-group__title {
            font-size: 14px;
            font-weight: 700;
          }
        }

        .info-group__body {
          width: 100%;

          .info-group__filter {
            width: 100%;
            background-color: #fff;
            padding: 16px;
            border-bottom: 1px solid $border-color;

            .btn-export-t-shirt {
              width: 100%;
            }

            .filter-categories {
              width: 100%;
              margin-bottom: 12px;
              

              .filter-categories__label {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 4px;
              }
            }
          }

          .info-group__board {
            width: 100%;
            padding: 16px;

            .t-shirt-item {
              width: 100%;
              padding: 16px;
              border-radius: 4px;
              background-color: #fff;
              margin-bottom: 8px;

              .t-shirt-row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;

                .t-shirt-label {
                  font-size: 14px;
                  font-weight: 500;
                  width: 100px;
                }

                .t-shirt-value {
                  font-size: 14px;
                  font-weight: 400;
                  width: calc(100% - 112px);
                }
              }
            }

            .participant-item {
              width: 100%;
              padding: 16px;
              border-radius: 4px;
              background-color: #fff;
              margin-bottom: 8px;

              .participant-row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;

                .participant-label {
                  font-size: 14px;
                  font-weight: 500;
                  width: 100px;
                }

                .participant-value {
                  font-size: 14px;
                  font-weight: 400;
                  width: calc(100% - 112px);
                }
              }
            }
          }
        }
      }
    }
  }
}
