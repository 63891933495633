@import "variables";

#edit-profile {
  width: 100%;
  color: $primary-text-color;

  .form-profile {
    width: 100%;

    .form-info {
      width: calc(100% - 48px);
      margin: 24px 24px 0;
      border-radius: 4px;
      background-color: #fff;

      .form-header {
        width: 100%;
        height: 64px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;

        .header-label {
          font-size: 16px;
          font-weight: 700;
        }

        .btn-edit {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border: 1px solid #a7b4fe;
          border-radius: 4px;
          color: $primary-color;

          .icon-edit {
            color: $primary-color;
            margin-right: 5px;
          }
        }
      }

      .form-body {
        width: 100%;
        padding: 24px;

        .avatar-group {
          width: 88px;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          border: 1px solid $border-color;
          margin-bottom: 24px;
          background-color: #F6F5FE;

          .icon-upload-image {
            color: $primary-color;
          }

          .avatar {
            width: 88px;
            height: 88px;
            object-fit: cover;
            border-radius: 40px;
          }
        }

        .form {
          width: 100%;

          .form-row {
            width: 100%;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;

            .form-item {
              width: calc(50% - 8px);

              .form-label-group {
                margin-bottom: 8px;
                display: flex;
                align-items: center;

                .form-label {
                  font-size: 14px;
                  font-weight: 500;
                  margin-right: 4px;
                }
              }

              .event-list {
                border: 1px solid $border-color;
                border-radius: 4px;
                padding: 8px 12px;
                background-color: #f5f7f6;
                cursor: default;
                color: #868E95;
              }

              .text-length {
                font-size: 14px;
                color: #a8b3bd;
                margin-top: 4px;
                text-align: right;
              }

              .field-group {
                width: 100%;
                .input {
                  width: 100%;
                  border: 1px solid $border-color;
                  border-radius: 4px;
                }

                .disabled {
                  background-color: #F5F7F6;
                  color: #868E95;
                }

                .field-note {
                  font-size: 14px;
                  color: #A8B3BD;
                  margin-top: 8px;
                }

                .field-error {
                  font-size: 12px;
                  color: red;
                  margin-top: 4px;
                }
              }
            }

            .phone-group {
              width: 100%;
              height: 44px;
              border: 1px solid $border-color;
              border-radius: 4px;
              display: flex;
              align-items: center;

              .select-phone-code {
                width: 100px;
                border: none;

                button {
                  border: none;
                  width: 80px;
                }

                label {
                  display: none;
                }

                div:has(> button) {
                  min-width: 100px;
                }

                ul {
                  min-width: 120px;
                }
              }

              .input-phone-group {
                width: calc(100% - 100px);

                .input-phone {
                  width: 100%;
                  border: none;
                }

                .input-phone:focus {
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .btn-group {
    width: calc(100% - 20vw);
    height: 80px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    left: 20vw;

    .btn-cancel {
      border: 0.5px solid #e9ecff;
      color: $primary-color;
      background-color: #fff;
      height: 48px;
    }

    .btn-save {
      background-color: $primary-color;
      border: 0.5px solid $primary-color;
      color: #fff;
      margin-left: 12px;
      height: 48px;
    }
  }
}
