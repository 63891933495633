@import 'variables';

#attraction-attendees {
  width: 100%;
  color: $primary-text-color;

  .attendees-form {
    width: 100%;

    .attendees-form__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 40px;
      background-color: #fff;
      padding: 10px 40px;

      .header-title {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .attendees-form__body {
      width: calc(100% - 32px);
      margin: 16px;

      .attendees-form__filter-group {
        width: 100%;
        padding: 24px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;

        .filter-item {
          margin-bottom: 16px;
          .filter-label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }

          .input-search {
            width: 100%;
            height: 38px;
            border-radius: 4px;
            border: 1px solid $border-color;
            font-size: 14px;
          }

          .filter-item__select {
            font-size: 14px;
          }
        }

        .search, .events {
          width: calc(50% - 6px);
        }
  
        .search {
          margin-right: 12px;
        }
        
        .categories, .status {
          width: 200px;
          margin-right: 12px;
        }

        .btn-search {
          width: 133px;
          height: 38px;
          border: 1px solid #A6A1FD;
          color: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background-color: #fff;
          margin-top: 28px;

          .icon-search {
            margin-right: 4px;
            color: $primary-color;
          }
        }
      }

      .attendees-form__board {
        width: 100%;
        padding: 16px;
        background-color: #fff;
        margin-top: 12px;
        border-radius: 4px;
  
        table {
          thead {
            background-color: #E9ECFF;
  
            .table-label {
              font-size: 14px;
              font-weight: 500;

              display: flex;
              align-items: center;

              .icon-tooltip {
                margin-left: 4px;
              }
            }
          }

          .table-link {
            color: $primary-color;
            cursor: pointer;
          }

          .table-row {
            border-bottom: 1px solid #D9E1E9;
          }
  
          .table-row:hover {
            background-color: #F6F5FE;
          }
        }
  
        .board__bottom {
          margin-top: 16px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          .board__bottom__total-item {
            font-size: 14px;
            font-weight: 500;
            width: 200px;
          }
  
          #pagination {
            margin-top: 0;
            justify-content: flex-end;

            li {
              margin: 0 5px;
            }
          }
        }
      }

      
    }
  }
}