@import "variables";

#desktop {
  width: 100vw;
  height: 100vh;
  font-family: "Roboto" !important;
  overflow-y: auto;

  .desktop-left {
    width: 20vw;
    height: 100vh;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .desktop-left-hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    display: none;
  }

  .desktop-right {
    width: 80vw;
    transition: width 0.5s ease-in-out;
  }

  .desktop-right-full {
    width: 100vw;
    transition: width 0.5s ease-in-out;
  }

  .header-fixed,
  .header-fixed-full {
    width: 80vw;
    height: 72px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  }

  .header-fixed-full {
    width: 100vw;
  }

  .layout-menu {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    .hamburger-icon {
      color: $primary-color;
      cursor: pointer;
    }

    .layout-menu-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .layout-content {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 72px);
    background-color: #f6f5fe;
    display: flex;
    flex-direction: column;
    align-items: center;

    .access-denied {
      margin-top: 150px;
      color: $primary-text-color;
      text-align: center;

      .access-denied__label {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 16px;
      }

      .access-denied__content {
        font-size: 20px;
        margin-bottom: 8px;
      }
    }

    .select__control {
      border: 1px solid $border-color;
      font-size: 14px;
      color: $primary-text-color;

      .select__placeholder {
        color: $primary-text-color;
      }

      .select__dropdown-indicator {
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
      }

      .select__input {
        --tw-ring-color: none;
      }
    }

    .select__control--menu-is-open {
      .select__dropdown-indicator {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
      }
    }

    .select__indicator-separator {
      display: none;
    }

    .select__control--is-focused {
      outline: none;
      border-color: $border-color;
      box-shadow: none;
    }
  }
}
