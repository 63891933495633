@import 'variables';

#change-password-mobile {
  width: 100%;
  height: 100%;
  color: #2B3033;

  .form-group {
    width: 100%;
    height: calc(100% - 48px);

    .form {
      width: calc(100% - 16px);
      margin: 8px auto 0;
      background-color: #fff;
      border-radius: 8px;

      .form-header {
        width: 100%;
        height: 64px;
        padding: 0 16px;
        border-bottom: 0.5px solid #D9E1E9;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
      }

      .form-body {
        width: 100%;
        padding: 16px;

        .form-item {
          margin-bottom: 17px;

          .form-label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }

          .field-group {
            .pass-group {
              display: flex;
              width: 100%;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;

              .input {
                width: 100%;
                height: 44px;
                border-radius: 4px;
                border: 0.5px solid #c5ccd2;
              }

              .icon-show-pass {
                position: absolute;
                margin-right: 10px;
              }
            }
          }

          .field-error {
            color: #df4941;
            font-size: 12px;
            font-weight: 400;
            margin: 8px 0;
          }
        }
      }
    }

    .btn-group {
      width: 100%;
      height: 80px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;
      position: fixed;
      bottom: 0;

      .btn-save, .btn-cancel {
        width: 88px;
        height: 48px;
        border: 0.5px solid $primary-color;
      }

      .btn-save {
        background-color: $primary-color;
        color: #fff;
        margin-left: 12px;
      }

      .btn-cancel {
        background-color: #fff;
        color: $primary-color; 
      }
    }
  }
}