@import 'variables';

#account-detail {
  width: calc(100% - 32px);
  height: auto;
  margin: 16px;
  background-color: #fff;
  border-radius: 4px;
  color: $primary-text-color;

  .account-detail-info {
    width: 100%;

    .form-header {
      width: 100%;
      height: 64px;
      border-bottom: 1px solid $border-color;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-label {
        font-size: 16px;
        font-weight: 600;
      }

      .btn-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 1px solid #A6A1FD;
        border-radius: 4px;
        
        .icon-edit {
          color: $primary-color;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: $primary-color;
        }
      }
    }

    .form-body {
      width: 100%;
      padding: 24px;

      .form-item {
        width: 100%;
        min-height: 72px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
        padding: 16px 0;

        .form-left {
          width: 30%;
          display: flex;
          align-items: center;

          .icon-group {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FEEFE7;
            margin-right: 16px;

            .icon {
              color: #F1600D;
            }
          }

          .form-item-title {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .form-right {
          width: 70%;
        }
      }

      .form-item:last-child {
        border-bottom: none;
      }
    }
  }
}