@import 'variables';

#coupon {
  width: 100%;
  color: $primary-text-color;

  .coupon-form__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: #fff;
    padding: 0 40px;

    .header-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .coupon-form {
    width: calc(100% - 32px);
    margin: 16px;

    .coupon-form__filter {
      width: 100%;
      padding: 16px 16px 0;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;

      .filter-item {
        margin-bottom: 8px;
        .filter-item__label {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .filter-item__input {
          width: 100%;
          height: 38px;
          border: 1px solid $border-color;
          font-size: 14px;
          border-radius: 4px;
        }

        .filter-item__select {
          height: 40px;
          border-radius: 4px;
        }
      }

      .coupon {
        width: calc(50% - 12px);
      }

      .status {
        width: 203px;
      }

      .events {
        width: 50%;
        margin-left: 12px;
      }

      .btn-search, .btn-export {
        width: 136px;
        height: 38px;
        border: 1px solid #A6A1FD;
        background-color: #fff;
        color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin: 30px 0 0 12px;
        
        .icon-search {
          color: $primary-color;
          margin-right: 4px;
        }
      }

      .btn-export {
        width: auto;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: #fff;
        margin-bottom: 10px;
      }
    }

    .coupon-form__board {
      width: 100%;
      padding: 16px;
      background-color: #fff;
      margin-top: 12px;
      border-radius: 4px;

      table {
        thead {
          background-color: #E9ECFF;

          .table-label {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .table-row {
          border-bottom: 1px solid #D9E1E9;
        }
  
        .table-row:hover {
          background-color: #F6F5FE;
        }

        .table-body__status {
          padding: 5px 16px;
          border-radius: 16px;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
        }

        .created {
          background-color: #DCFCE7;
          color: #1D813F;
        }

        .using {
          background-color: #FEF9C3;
          color: #A16207;
        }

        .out-of-usage {
          background-color: #F3E8FF;
          color: #7E22D7;
        }

        .expired {
          background-color: #FFCFDD;
          color: #9D0520;
        }
      }

      .board__bottom {
        margin-top: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .board__bottom__total-item {
          font-size: 14px;
          font-weight: 500;
          width: 200px;
        }

        #pagination {
          margin-top: 0;
          justify-content: flex-end;
        }
      }
    }
  }
}