@import "variables";

#sport-attendees-detail-mobile {
  width: 100%;
  color: $primary-text-color;

  .attendees-detail-form {
    width: 100%;

    .detail-form__header {
      width: 100%;
      height: 40px;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: 16px;
      font-weight: 700;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }

    .detail-form__body {
      width: calc(100% - 32px);
      margin: 16px 16px 0;
      padding-bottom: 100px;

      .form-item {
        width: 100%;
        border-radius: 4px;
        background-color: #fff;
        margin-bottom: 16px;

        .form-item__header {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid $border-color;

          .form-item__header__label {
            font-size: 14px;
            font-weight: 700;
            margin-left: 16px;
          }
        }

        .form-item__body {
          padding: 16px;

          .form-item__information {
            width: 100%;
            display: flex;
            font-size: 14px;
            margin-bottom: 16px;

            .form-item__information__label {
              width: 138px;
              font-weight: 500;
            }

            .form-item__information__value {
              width: calc(100% - 150px);
              margin-left: 12px;
              white-space: pre-wrap;
              word-wrap: break-word;
            }

            .order-code {
              color: $primary-color;
              cursor: pointer;
            }
          }

          .form-item__group {
            margin-bottom: 16px;
            font-size: 14px;

            .group-name {
              font-weight: 600;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
