@import "variables";

#account-detail-mobile {
  width: 100%;
  color: $primary-text-color;
  padding-bottom: 100px;

  .account-detail-info {
    width: calc(100% - 16px);
    background-color: #fff;
    margin: 8px 8px 100px;
    border-radius: 4px;

    .form-header {
      width: 100%;
      height: 64px;
      border-bottom: 0.5px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      .form-label {
        font-size: 16px;
        font-weight: 600;
      }

      .btn-edit {
        border-radius: 4px;
        border: 1px solid #F6F5FE;
        background-color: #fff;
        

        .icon-edit {
          color: $primary-text-color;
        }
      }
    }

    .form-body {
      width: 100%;
      padding: 24px 16px;

      .form-item {
        width: 100%;
        min-height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 0.5px solid $border-color;
        padding: 16px 0;

        .form-left {
          display: flex;

          .icon-group {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FEEFE7;
            margin-right: 12px;

            .icon {
              color: #F1600D;
            }
          }

          .form-item-title {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .form-right {
          font-size: 14px;
          font-weight: 400;
          margin-left: 54px;
          margin-top: -15px;
        }
      }

      .form-item:last-child {
        border-bottom: none;
      }
    }
  }
}