@import 'variables';

#edit-role-mobile {
  width: 100%;
  color: $primary-text-color;

  .form-create-role {
    width: 100%;
    padding-bottom: 100px;

    .form {
      width: calc(100% - 16px);
      border-radius: 4px;
      background-color: #fff;
      margin: 8px auto;

      .form-header {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        padding: 0 24px;
        border-bottom: 1px solid $border-color;
      }

      .form-body {
        padding: 16px;

        .form-item {
          width: 100%;
          margin-bottom: 16px;

          .switch {
            background-image: none;
          }
  
          .form-label-group {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
  
            .form-label {
              font-size: 14px;
              font-weight: 500;
            }
  
            .icon-require {
              margin: 0 4px 0 2px;
            }
          }

          .input {
            width: 100%;
            height: 44px;
            border-radius: 4px;
            border: 1px solid $border-color;
          }

          .text-length {
            font-size: 14px;
            color: #a8b3bd;
            margin-top: 4px;
            text-align: right;
          }

          .select-role {
            width: 100%;
            height: 44px;
            border-radius: 4px;
            border: 1px solid $border-color;
            
            button {
              border: none;
            }
  
            label {
              display: none;
            }

            .multi-select {
              border: none;
            }
          }

          .phone-group {
            width: 100%;
            height: 44px;
            border: 1px solid $border-color;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .select-phone-code {
              width: 100px;
              border: none;

              button {
                border: none;
                width: 80px;
              }

              label {
                display: none;
              }

              div:has(> button) {
                min-width: 100px;
              }

              ul {
                min-width: 120px;
              }
            }

            .input-phone-group {
              width: calc(100% - 100px);
              .input-phone {
                border: none;
              }

              .input-phone:focus {
                outline: none;
              }
            }
            
          }

          .field-error {
            font-size: 12px;
            color: red;
            margin-top: 4px;
          }

          .role-row-mobile {
            width: 100%;
            padding: 8px;
            border-radius: 4px;
            background-color: #f6f5fe80;
            margin-top: 8px;

            .role-label {
              font-size: 14px;
              font-weight: 400;
            }

            .role-row-left {
              display: flex;
              align-items: center;

              .icon-permission {
                margin-right: 8px;
              }

              .role-label {
                font-size: 14px;
                font-weight: 500;
              }
            }

            .role-row-right {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;

              .checkbox-item {
                width: 50%;
                display: flex;
                align-items: center;

                .checkbox {
                  border-radius: 4px;
                }

                .checkbox:checked {
                  background-color: #f1600d;
                  outline: none;
                  border: 1px solid #f1600d;
                }

                .checkbox-label {
                  user-select: none;
                }
              }
            }
          }

          .role-child {
            margin-top: 0;
            border-top: 0.5px solid $border-color;

            .role-row-left {
              padding-left: 30px;
            }
          }
        }
      }
      
    }

    .btn-group {
      width: 100%;
      height: 80px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;
      position: fixed;
      bottom: 0;

      .btn-save, .btn-cancel {
        width: 88px;
        height: 48px;
        border: 0.5px solid $primary-color;
      }

      .btn-save {
        background-color: $primary-color;
        color: #fff;
        margin-left: 12px;
      }

      .btn-cancel {
        background-color: #fff;
        color: $primary-color; 
      }
    }
  }

  .hide-in-mobile {
    display: none !important;
  }
}