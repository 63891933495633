@import 'variables';

#change-password {
  width: 100%;
  height: 100%;
  color: #2b3033;

  .form-group {
    width: 100%;
    height: calc(100% - 48px);

    .form {
      width: 40%;
      min-width: 448px;
      border-radius: 8px;
      background-color: #fff;
      margin: 16px auto 0;
      padding-bottom: 16px;

      .form-header {
        width: 100%;
        height: 56px;
        padding: 0 17px;
        border-bottom: 0.5px solid #d9e1e9;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
      }

      .form-body {
        width: 100%;
        padding: 17px 17px 0;

        .form-item {
          margin-bottom: 17px;

          .form-label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }

          .field-group {
            .pass-group {
              display: flex;
              width: 100%;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;

              .input {
                width: 100%;
                height: 44px;
                border-radius: 4px;
                border: 0.5px solid #c5ccd2;
              }

              .icon-show-pass {
                position: absolute;
                margin-right: 10px;
              }
            }
          }

          .field-error {
            color: #df4941;
            font-size: 12px;
            font-weight: 400;
            margin: 8px 0;
          }
        }
      }
    }

    .btn-group {
      width: calc(100% - 20vw);
      height: 80px;
      background-color: #fff;
      position: fixed;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;

      .btn-cancel {
        border: 0.5px solid #e9ecff;
        color: $primary-color;
        background-color: #fff;
        height: 48px;
      }

      .btn-save {
        background-color: $primary-color;
        border: 0.5px solid $primary-color;
        color: #fff;
        margin-left: 12px;
        height: 48px;
      }
    }
  }
}
