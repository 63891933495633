.menu-user {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .user-avt {
    width: 32px;
    height: 32px;
    border-radius: 40px;
    background-color: #F6F5FE;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-name {
    margin: 0 10px;
    font-size: 14px;
  }
}