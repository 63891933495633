#message, #message-mobile {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 85px;
  left: 0;
  z-index: 100;

  .success, .error {
    width: 60%;
    height: auto;
    min-height: 56px;
    background-color: #E5F3E0;
    color: #144E00;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 8px;

    .icon-success {
      margin-right: 10px;
    }

    .message-success {
      font-size: 14px;
      color: #144E00;
    }
  }

  .error {
    background-color: #ffcccc;

    .icon-success {
      color: #e50000;
    }

    .message-error {
      font-size: 14px;
      color: #e50000;
      font-weight: 600;
    }
  }
}

#message-mobile {
  .success, .error {
    width: 90%;
  }
}