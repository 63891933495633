@import "variables";

#create-role {
  width: 100%;
  height: 100%;
  color: $primary-text-color;

  .form-create-role {
    width: 100%;
    padding-bottom: 100px;

    .form {
      width: 80%;
      background-color: #fff;
      margin: 16px auto 0;
      border-radius: 4px;

      .form-header {
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color;
        font-size: 16px;
        font-weight: 700;
        padding: 0 24px;
      }

      .form-body {
        width: 100%;
        padding: 24px;

        .form-row {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .form-item {
            width: 100%;

            .form-label-group {
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 8px;

              .form-label {
                font-size: 14px;
                font-weight: 500;
              }

              .icon-require {
                margin: 0 4px 0 2px;
              }
            }

            .switch {
              background-image: none;
            }

            .field-group {
              width: calc(100% - 12px);

              .input {
                width: 100%;
                height: 44px;
                border: 1px solid $border-color;
                border-radius: 4px;
              }

              .text-length {
                font-size: 14px;
                color: #a8b3bd;
                margin-top: 4px;
                text-align: right;
              }
            }

            .select-role {
              width: calc(100% - 12px);
              height: 44px;
              border-radius: 4px;
              border: 1px solid $border-color;

              button {
                border: none;
              }

              label {
                display: none;
              }
            }

            .phone-group {
              width: calc(100% - 12px);
              height: 44px;
              border: 1px solid $border-color;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .select-phone-code {
                width: 100px;
                border: none;

                button {
                  border: none;
                  width: 80px;
                }

                label {
                  display: none;
                }

                div:has(> button) {
                  min-width: 100px;
                }

                ul {
                  min-width: 120px;
                }
              }

              .input-phone-group {
                .input-phone {
                  border: none;
                }

                .input-phone:focus {
                  outline: none;
                }
              }
            }

            .field-error {
              font-size: 12px;
              color: red;
              margin-top: 4px;
            }

            .role-group {
              width: 100%;
              height: auto;

              .role-row {
                width: 100%;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 4px;
                background-color: #f6f5fe80;
                padding: 24px;
                margin-top: 8px;

                .icon-permission {
                  margin-right: 8px;
                }

                .role-label {
                  font-size: 14px;
                  font-weight: 400;
                }

                .role-row-left {
                  width: calc(30% - 7px);
                  display: flex;
                  align-items: center;
                }

                .role-row-left-child {
                  padding-left: 20px;
                }

                .role-row-right {
                  width: calc(70% - 7px);
                  display: flex;
                  align-items: center;

                  .checkbox-item {
                    width: calc((100% / 4) - 5px);
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;

                    .checkbox {
                      border-radius: 4px;
                    }

                    .checkbox:checked {
                      background-color: #f1600d;
                      outline: none;
                      border: 1px solid #f1600d;
                    }

                    .checkbox-label {
                      user-select: none;
                    }
                  }
                }
              }

              .role-child {
                margin-top: 0;
                border-top: 0.5px solid $border-color;

                .role-row-left {
                  padding-left: 30px;
                }
              }

              .action-item {
                margin-top: 8px;

                .parent-label {
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .form-row:not(:first-child) {
          margin-top: 16px;
        }
      }
    }

    .btn-group {
      width: calc(100% - 20vw);
      height: 80px;
      background-color: #fff;
      position: fixed;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;

      .btn-cancel {
        border: 0.5px solid #e9ecff;
        color: $primary-color;
        background-color: #fff;
        height: 48px;
      }

      .btn-save {
        background-color: $primary-color;
        border: 0.5px solid $primary-color;
        color: #fff;
        margin-left: 12px;
        height: 48px;
      }
    }
  }
}
