
#sidebar {
  border-radius: 0;
  background-color: #fff;

  .logo-company {
    width: 100%;
    height: 72px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D9E1E9;
    margin-bottom: 8px;
  }

  .hide-menu {
    display: none;
  }
  
  nav {
    padding: 0 8px;
    margin-bottom: 8px;
    min-height: 52px;
  }

  .child-item {
    min-height: 52px;
    margin-top: 8px;
  }

  .menu-name {
    font-size: 14px;
    color: #262626;
    font-weight: 400;
  }
}