@import "variables";

#edit-organization-ui {
  width: 100%;
  height: 100%;
  color: #2b3033;

  .form-group {
    width: 100%;
    height: auto;
    min-height: calc(100% - 48px);
    padding-bottom: 100px;

    .form {
      width: 80%;
      min-width: 448px;
      border-radius: 8px;
      background-color: #fff;
      margin: 16px auto 0;
      padding-bottom: 16px;

      .form-header {
        width: 100%;
        height: 56px;
        padding: 0 17px;
        border-bottom: 0.5px solid #d9e1e9;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
      }

      .form-body {
        width: 100%;
        padding: 17px 17px 0;

        .form-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;

          .form-row-item {
            width: calc(50% - 12px);

            .form-label-group {
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 4px;

              .form-label {
                font-size: 14px;
                font-weight: 500;
                margin-right: 4px;
              }
            }

            .upload-image-group {
              width: 100%;
              height: 173px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background-color: #f5f6f7;
              border-radius: 4px;
              cursor: pointer;
              border: 0.5px dashed #c5ccd2;

              .icon-upload-image {
                color: #d9e1e9;
              }

              .label-upload-image {
                font-size: 14px;
                font-weight: 400px;
                color: #868e95;
                margin: 32px 0 10px;
              }

              .btn-select-image {
                font-size: 14px;
                font-weight: 700;
                color: $primary-color;
              }
            }

            .image-uploaded-group {
              width: 100%;
              height: 173px;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              flex-direction: column;
              background-color: #f5f6f7;
              border-radius: 4px;
              cursor: pointer;
              border: 0.5px dashed #c5ccd2;

              .image-uploaded {
                max-width: 80%;
                max-height: 150px;
                margin: auto;
                position: relative;
                top: 15px;
                object-fit: contain;
              }

              .organization-logo {
                width: 150px;
                height: 150px;
              }

              .btn-delete-image {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                bottom: 110px;
                right: 45px;
              }
            }

            .drag-drop {
              width: 100%;
              height: 173px;
              svg {
                display: none;
              }

              span {
                display: none;
              }
            }
          }
        }

        .form-item {
          width: 100%;
          margin-bottom: 32px;

          .form-label-group {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            .form-label {
              font-size: 14px;
              font-weight: 500;
              margin-right: 4px;
            }

            .icon-require {
              margin: 0 4px 0 2px;
            }
          }

          .brand-color-group {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            border: 1px solid $border-color;
            display: flex;
            align-items: center;
            padding: 0 16px;

            .color-demo {
              width: 32px;
              height: 32px;
              border-radius: 4px;
              margin-right: 8px;
            }

            .color-spell {
              font-size: 16px;
              font-weight: 700;
              color: #a8b3bd;
            }

            .input-color {
              width: 100%;
              border: none;
              color: #000;
              font-size: 16px;
              font-weight: 700;
              min-width: 300px;
            }

            input:focus {
              outline: none;
            }
          }

          .introduction-group {
            width: 100%;
            margin-bottom: 4px;

            .introduction-language {
              width: 164px;
              height: 40px;
              background-color: #e9ecff;
              display: flex;
              align-items: center;
              padding: 0 16px;
              font-size: 14px;
              font-weight: 500;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }

            .introduction-input {
              width: 100%;
              height: 100px;
              border-radius: 4px;
              border: 1px solid $border-color;
            }

            .organization-name {
              width: 100%;
              height: 41px;
              border-radius: 4px;
              border: 1px solid $border-color;
              display: flex;
              align-items: center;
              padding: 0 16px;
              background-color: #f5f7f6;
              color: #868e95;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .select-country {
            border-radius: 10px;
            border: 1px solid $border-color;

            button {
              border: none;
            }

            label {
              display: none;
            }
          }

        }

        .error {
          font-size: 12px;
          color: red;
          margin-top: 5px;
        }
      }
    }

    .btn-group {
      width: calc(100% - 20vw);
      height: 80px;
      background-color: #fff;
      position: fixed;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;

      .btn-cancel {
        border: 0.5px solid #e9ecff;
        color: $primary-color;
        background-color: #fff;
        height: 48px;
      }

      .btn-save {
        background-color: $primary-color;
        border: 0.5px solid $primary-color;
        color: #fff;
        margin-left: 12px;
        height: 48px;
      }
    }
  }
}
