@import "variables";

#edit-order {
  width: 100%;
  color: $primary-text-color;

  .order-form {
    width: 100%;

    .order-form__header {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-top: 0.5px solid $border-color;
      border-bottom: 0.5px solid $border-color;
      padding: 0 24px;

      .header__label {
        font-size: 20px;
        font-weight: 700;
      }

      .btn-resend {
        width: 350px;
        height: 40px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
        color: #fff;
        font-size: 14px;
        border-radius: 4px;

        .icon-email {
          color: #fff;
          margin-right: 8px;
        }
      }
    }

    .order-form__body {
      width: 100%;
      padding: 24px 24px 100px;

      .body__item {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 16px;

        .body__item__header {
          width: 100%;
          min-height: 64px;
          border-bottom: 0.5px solid $border-color;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 24px;

          .body__item__header__label {
            font-size: 16px;
            font-weight: 700;
          }
        }

        .body__item__content {
          width: 100%;
          padding: 24px 24px 8px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .body__item__content-label {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 16px;
          }

          .content__item {
            width: calc(50% - 6px);
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            margin-bottom: 16px;

            .content__item__label {
              font-weight: 500;
              width: 180px;
            }

            .content__item__value {
              width: calc(100% - 196px);

              .send-email-payment-success {
                color: #16831c;
                margin-left: 4px;
              }
            }

            .order-code {
              font-size: 16px;
              font-weight: 700;
              color: #081774;
            }

            .status {
              padding: 5px 16px;
              border-radius: 16px;
              font-size: 12px;
              font-weight: 500;
              text-align: center;
            }

            .pending {
              background-color: #f3e8ff;
              color: #7e22d7;
            }

            .success {
              background-color: #dcfce7;
              color: #1d813f;
            }

            .canceled {
              background-color: #ffcfdd;
              color: #9d0520;
            }

            .refund {
              background-color: #fef9c3;
              color: #a16207;
            }
          }

          .full {
            width: 100%;
          }
        }

        .purchased {
          flex-direction: column;

          .purchased__board {
            thead {
              background-color: #e9ecff;

              .table-label {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }

          .body__item__search-group {
            width: 100%;

            .search-group__label {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
            }

            .search-group__row {
              width: 100%;
              display: flex;

              .search-group__input {
                width: 250px;
                height: 40px;
                border-radius: 4px;
                border: solid 1px $border-color;
                font-size: 14px;
                margin-right: 12px;
              }

              .btn-search {
                width: 150px;
                height: 40px;
                border: 1px solid #a6a1fd;
                background-color: #fff;
                color: $primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;

                .icon-search {
                  color: $primary-color;
                  margin-right: 4px;
                }
              }
            }

            .participants {
              width: 100%;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              margin-top: 16px;

              .participant-item {
                width: calc(25% - 11px);
                border-radius: 8px;
                border: 1px solid $border-color;
                margin-bottom: 16px;
                padding: 16px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                margin-right: 11px;

                .participant-item__avatar {
                  width: 40px;
                  height: 40px;
                  border-radius: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f6f5fe;

                  .icon-avatar {
                    color: #a7b4fe;
                  }
                }

                .participant-item__information {
                  width: calc(100% - 72px);
                  margin: 0 8px;
                  display: flex;
                  flex-direction: column;

                  .information__username {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 4px;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                  }

                  .information__email {
                    max-width: 100%;
                    font-size: 10px;
                    font-weight: 400;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                  }

                  .information__email-status {
                    padding: 4px 12px;
                    border-radius: 16px;
                    margin-top: 20px;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                  }

                  .sent {
                    background-color: #dcfce7;
                    color: #1d813f;
                  }

                  .not-sent {
                    background-color: #ffcfdd;
                    color: #9d0520;
                  }
                }

                .icon-email {
                  width: 16px;
                  height: 16px;
                  color: $primary-color;
                }
              }
            }
          }

          .attendant-bottom {
            margin-bottom: 16px;
            #pagination {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    .select-phone-code {
      width: 100px;
      border: none;

      button {
        border: none;
        width: 80px;
      }

      label {
        display: none;
      }

      div:has(> button) {
        min-width: 100px;
      }

      ul {
        min-width: 120px;
      }
    }

    .input-phone {
      width: 100%;
      border: none;
    }

    .input-phone:focus {
      outline: none;
      --tw-ring-color: none;
    }
  }
}
