@import 'variables';

#edit-organization-ui-mobile {
  width: 100%;
  height: 100%;
  color: #2B3033;

  .form-group {
    width: 100%;
    height: auto;
    min-height: calc(100% - 48px);
    padding-bottom: 70px;

    .form {
      width: calc(100% - 16px);
      margin: 8px auto 0;
      background-color: #fff;
      border-radius: 8px;

      .form-header {
        width: 100%;
        height: 64px;
        padding: 0 16px;
        border-bottom: 0.5px solid #D9E1E9;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
      }

      .form-body {
        width: 100%;
        padding: 16px;

        .form-row-item, .form-item {
          width: 100%;
          margin-bottom: 24px;

          .form-label-group {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .form-label {
              font-size: 14px;
              font-weight: 500;
              margin-right: 4px;
            }
          }

          .upload-image-group {
            width: 100%;
            height: 173px;
            border-radius: 4px;
            border: 1px dashed $border-color;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 8px;

            .icon-upload-image {
              color: #D9E1E9;
              margin-bottom: 6px;
            }

            .label-upload-image {
              color: #868E95;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 4px;
            }

            .btn-select-image {
              color: #011BB6;
              font-size: 14px;
              font-weight: 700;
            }
          }

          .image-uploaded-group {
            width: 100%;
            height: 173px;
            border-radius: 4px;
            background-color: #F5F6F7;
            border: 1px dashed $border-color;
            display: flex;
            align-items: center;

            .image-uploaded {
              width: 80%;
              max-height: 150px;
              margin: auto;
              object-fit: contain;
            }

            .organization-logo {
              width: 150px;
              height: 150px;
            }

            .btn-delete-image {
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 40px;
              background-color: #fff;
              position: absolute;
              right: 16%;
              margin-top: -65px;
            }
          }
        }

        .form-item {
          .brand-color-group {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            border: 1px solid $border-color;
            display: flex;
            padding: 0 16px;
            align-items: center;

            .color-demo {
              width: 32px;
              height: 32px;
              border-radius: 4px;
              margin-right: 8px;
            }

            .color-spell {
              color: #A8B3BD;
              font-size: 16px;
              font-weight: 700;
            }

            .input-color {
              width: 100%;
              border: none;
              color: #000;
              font-size: 600;
              font-size: 700;
            }

            .input-color:focus {
              outline: none;
            }
          }

          .introduction-group {
            width: 100%;
            margin-bottom: 10px;

            .introduction-language {
              width: 98px;
              height: 44px;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              display: flex;
              align-items: center;
              padding: 0 16px;
              background-color: #e9ecff;
              font-size: 14px;
              font-weight: 500;
            }

            .introduction-input {
              width: 100%;
              height: 150px;
              border-radius: 4px;
              border: 1px solid $border-color;
            }

            .organization-name {
              width: 100%;
              height: 41px;
              border-radius: 4px;
              border: 1px solid $border-color;
              display: flex;
              align-items: center;
              padding: 0 16px;
              background-color: #f5f7f6;
              color: #868e95;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .select-country {
            border-radius: 10px;
            border: 1px solid $border-color;

            button {
              border: none;
            }

            label {
              display: none;
            }
          }
        }
      }
    }

    .btn-group {
      width: 100%;
      height: 80px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;
      position: fixed;
      bottom: 0;

      .btn-save, .btn-cancel {
        width: 88px;
        height: 48px;
        border: 0.5px solid $primary-color;
      }

      .btn-save {
        background-color: $primary-color;
        color: #fff;
        margin-left: 12px;
      }

      .btn-cancel {
        background-color: #fff;
        color: $primary-color; 
      }
    }

    .error {
      font-size: 12px;
      color: red;
      margin-top: 8px;
    }
  }
}