@import 'variables';

#reset-password {
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 5deg at -8.78% 126%,
    #fdf7f4 0.2740371460095048deg,
    #faf1f8 4.876883961260319deg,
    #f4f2fe 9.16059672832489deg,
    #ecf5fe 20.33330887556076deg,
    #ebf4fd 36.175250709056854deg,
    #effbf2 58.38791370391846deg
  );
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: 80%;
    height: 700px;
    display: flex;
    align-items: center;

    .form {
      width: calc(100% - 220px);
      height: 630px;
      background-color: #fff;
      border-radius: 40px;
      padding: 0 110px;

      .form-body {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 35px;

          .logo {
            width: 124px;
            height: 56px;
          }

          .dropdown {
            border: 1px solid $border-color;
            border-radius: 16px;
            padding: 0 8px;

            button:focus {
              border: none;
              --tw-ring-color: none;
            }

            ul {
              margin-left: -8px;
            }
          }
        }

        .label {
          font-size: 21px;
          color: #19141e;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 32px;
        }

        .form-group {
          width: 100%;

          .form-item {
            width: 100%;
            margin-bottom: 16px;
            min-height: 75px;
            height: auto;

            .form-label {
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 8px;
            }

            .field-group {
              width: 100%;

              .input {
                width: 100%;
                height: 42px;
                border-radius: 4px;
                border: 1px solid #c5ccd2;
                padding: 0 10px;
                font-size: 14px;
              }

              .pass-group {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                .icon-show-pass-desk {
                  margin-right: 10px;
                  margin-top: -30px;
                }
              }

              .field-error {
                font-size: 14px;
                color: #df4941;
                margin: 15px 0;
              }
            }
          }

          .btn-submit {
            width: 100%;
            height: 62px;
            background-color: $primary-color;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 19px;
            font-weight: 700;
            cursor: pointer;
            border-radius: 4px;
            text-transform: capitalize;
            margin-bottom: 32px;
            margin-top: 44px;
          }
        }
      }
    }

    .btn-back-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #A1A5AC;
      font-size: 16px;

      .btn-back {
        color: $primary-color;
        cursor: pointer;
        margin-left: 5px;
      }

      .btn-back:hover {
        text-decoration: underline;
      }
    }

    .img-background {
      width: 30%;
      height: 700px;
      min-width: 400px;
      position: absolute;
      right: 10%;
    }
  }
}

.link-invalid {
  width: 100vw;
  height: 100vh;
  background-color: #F6F5FE;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-company {
    width: 134px;
    height: 60px;
    margin-top: 195px;
  }

  .link-invalid-title {
    margin: 18px 0 50px;
    color: #262626;
    font-size: 20px;
    font-weight: 700;
  }

  .link-invalid-content {
    font-size: 20px;
    font-weight: 400;
    color: #4F5559;
    margin-bottom: 50px;
    text-align: center;
  }

  .btn-forgor-password {
    width: 143px;
    height: 48px;
    border-radius: 4px;
    color: #fff;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px;
  }
}
