@import "variables";

#mobile {
  width: 100vw;
  height: auto;
  font-family: "Roboto" !important;
  overflow-y: auto;

  #sidebar {
    position: relative;
    z-index: 10;
    width: 256px !important;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  }

  .overlay {
    filter: grayscale(100%) blur(3px);
    opacity: 1; /* Adjust the opacity as needed */
  }

  .menu-fixed {
    position: fixed;
    width: 100%;
    height: 72px;
    background-color: #fff;
    top: 0;
    z-index: 100;
  }

  .header-collapse {
    width: calc(100vw - 256px);
    padding: 0 10px;
  }

  .layout-content {
    width: 100%;
    height: auto;
    background-color: #f6f5fe;
    margin-top: 72px;
    min-height: 100vh;

    .access-denied {
      padding-top: 150px;
      color: $primary-text-color;
      text-align: center;
      width: calc(100% - 32px);
      margin: 0 16px;

      .access-denied__label {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
      }

      .access-denied__content {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    .select__control {
      border: 1px solid $border-color;
      font-size: 14px;

      .select__dropdown-indicator {
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
      }

      .select__input {
        --tw-ring-color: none;
      }
    }

    .select__control--menu-is-open {
      .select__dropdown-indicator {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
      }
    }

    .select__indicator-separator {
      display: none;
    }
  }

  .user-name {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input::placeholder {
    font-size: 12px; /* Set your desired font size */
  }
}

input {
  touch-action: manipulation;
}